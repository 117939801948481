.btv-text-blue {
    color: #36a9d8!important;
}

.bg-btv-grey {
    background: #f2f2f2
}

.bg-grey {
    background-color: #f2f2f2
}

.bgf7f6f6 {
    background-color: #f7f6f6 !important;
}

.h5,
h5 {
    font-size: 24px;
    font-weight: 400;
    color: #0096d3
}

@media (max-width: 1000px) {
    .h5,
    h5 {
        font-size: 22px;
        font-weight: 400;
    }
}

@media (max-width: 1000px) {
    .h3,
    h3 {
        font-size: 26px
    }
}

.btn-default {
    border-color: #ccc;
    text-shadow: 0 1px 0 #fff;
    background-image: linear-gradient(to bottom, #fff 0%, #e0e0e0 100%)
}

.btn-default:hover {
    border-color: #adadad;
    background-image: linear-gradient(to bottom, #f0f0f0 0%, #c9c8c8 100%)
}

.btn-to-buy {
    color: #fff;
    background-color: #41b1e2;
    border-color: #34b3c7
}

.btn-to-buy:hover {
    color: #fff;
    text-shadow: 0 1px 0 #2c9cad;
    background-color: #2f96c2;
    border-color: #34b3c7
}


/*# sourceMappingURL=color.css.map */