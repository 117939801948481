 .blog {
     padding-top: 1rem!important;
     padding-bottom: 1.5rem!important;
 }
 
 .blog .row {
     padding-bottom: 1.5rem!important;
     padding-top: 1.5rem!important;
     align-items: center!important;
     justify-content: center!important;
     display: flex!important;
 }
 
 .blog h1,
 h2,
 h3,
 h4,
 h5 {
     margin-bottom: 1.5rem!important;
 }