@media (max-width: 320px) {
    h1 {}
    h2 {
        font-size: 20px !important;
        font-display: swap
    }
}

@media (max-width: 1200px) and (min-width: 321px) {
    h1 {
        font-size: calc(1.5rem + 6 * (100vw - 320px) / 880)
    }
    h2 {
        font-size: calc(1.4rem + 6 * (100vw - 320px) / 880)
    }
}

@media (min-width: 1201px) {
    h1 {
        font-size: 30px
    }
    h2 {
        font-size: 25px
    }
}

.lazy-load-box {
    opacity: 0;
    -moz-transform: scale(1) rotate(0deg) translateX(0px) translateY(0px) skewX(0deg) skewY(0deg);
    -webkit-transform: scale(1) rotate(0deg) translateX(0px) translateY(0px) skewX(0deg) skewY(0deg);
    -o-transform: scale(1) rotate(0deg) translateX(0px) translateY(0px) skewX(0deg) skewY(0deg);
    -ms-transform: scale(1) rotate(0deg) translateX(0px) translateY(0px) skewX(0deg) skewY(0deg);
    transform: scale(1) rotate(0deg) translateX(0px) translateY(0px) skewX(0deg) skewY(0deg)
}


/*# sourceMappingURL=font-size.css.map */