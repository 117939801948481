.call-back-block {
    background: url(/components/callback/bg.jpg);
    background-position: center;
    background-repeat: no-repeat
}

.call-back-input input,
select {
    max-width: 300px
}

.call-back-input {
    padding: 15px 10px 10px 30px;
    background-color: rgba(255, 255, 255, 0.576);
    max-width: 600px
}

.call-back-title {
    background-color: rgba(255, 255, 255, 0.9);
    max-width: 600px;
    font-size: 34px;
    font-weight: 500;
    color: #07abe2
}

.call-back-title label {
    font-size: 15px;
    color: #07abe2
}

.call-back-title textarea {
    font-size: 15px !important;
    min-height: 136px
}

@media (max-width: 1200px) and (min-width: 734px) {
    .call-back-title {
        max-width: 450px;
        font-size: 25px;
        background-color: rgba(255, 255, 255, 0.9)
    }

    .call-back-input {
        padding: 15px 10px 10px 30px;
        min-width: 370px
    }
}

@media (max-width: 734px) and (min-width: 321px) {
    .call-back-title {
        max-width: 370px;
        font-size: 20px;
        background-color: rgba(255, 255, 255, 0.9)
    }

    .call-back-input {
        padding: 15px 10px 10px 30px;
        width: 100%;
    }
}

.error {
    color: #ff0000
}


.triangle-bottom-get-a-consultation {
    width: 100vw;
    height: 10vh;
    background: linear-gradient(to bottom right, #f8f9fa 50%, transparent 50%);
}


/*# sourceMappingURL=call-back.css.map */