div.scrollup {
    position: fixed;
    color: #ffffffbf;
    background-color: #36a9d885;
    left: 10px;
    bottom: 0px;
    padding: 4px 10px;
    font-size: 20px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    cursor: pointer;
    display: none;
    z-index: 1;
    text-align: center
}

div.scrollup:active {
    color: #ffffffbf;
    background-color: #36a9d885;
}

div.scrollup:hover {
    background-color: #00000059
}


/*# sourceMappingURL=scrollup.css.map */