.logo-btv {
    width: 250px;
}

.logo-atpr {
    width: 200px;
}

header i {
    color: #0096d7;
    font-size: 18px;
    width: 20px;
}


@media (max-width: 513px) {

    .logo-btv {
        width: 360px;
        margin-top: 20px;
    }

    .logo-atpr {
        width: 300px;
    }

    .mobile-margin-bottom {
        margin-bottom: 30px;
    }

    .mobile-logo-fontsize {
        font-size: 30px;
    }

    .logo-atpr {
        width: 230px;
    }

    header i {
        color: #0096d7;
        font-size: 30px;
        width: 20px;
    }

    .mobile-margin-bottom-num {
        margin-bottom: 30px;
    }
}


@media (min-width: 514px) and (max-width: 900px) {
    .mobile-margin-bottom {
        margin-bottom: 20px;
    }

    .mobile-logo-fontsize {
        font-size: 18px;
    }

    .logo-atpr {
        width: 230px;
    }


}


@media (min-width: 991px) and (max-width: 1063px) {

    /* Применяется только в диапазоне ширины экрана от 991px до 1063px */
    .mobile-margin-bottom {
        margin-bottom: 20px;
    }
}