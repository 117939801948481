/*# sourceMappingURL=body.css.map */
.text-indent {
    text-indent: 1em;
}


@media (max-width: 1200px) and (min-width: 1001px) {
    .get-a-consultation-tel a {
        font-size: large;
    }
}


.product-scale {
    background-color: #EBEBEB;
    margin: 10px auto;
    position: relative;
    text-align: left;
    width: 205px;
    border-radius: 4px;
    height: 22px;
    overflow: hidden;
    font-size: 13px;
}

.warm {
    background-color: rgb(218, 196, 167);
}

.silence {
    background-color: rgb(184, 215, 193);
}

.product-scale div {
    width: 121px;
    height: 22px;
    line-height: 23px;
    position: absolute;
    padding-left: 10px;
    color: rgb(0, 0, 0);
    border-radius: 4px 0 0 4px;
}

.zoomable {
    transition: transform 0.25s ease-out;
}

.zoomable:hover {
    transform: scale(1.05);
}