    .breadcrumb-btv {
        font-size: 12px;
        padding: 3px 10px;
        background-color: #fff;
        margin-bottom: 0px;
        flex-wrap: wrap
    }
    
    @media (min-width:320px) and (max-width:414px) {
        .breadcrumb-btv {
            margin-bottom: 10px;
            margin-top: 20px;
        }
    }
    /*# sourceMappingURL=breadcrumb.css.map */