.module-social-icons {
    background-color: #f2f2f2;
    padding-top: 15px;
    padding-bottom: 5px;
    text-align: center;
    color: #36a9d8;
}

.module-social-icons h3 {
    margin-top: 15px;
}

.social-icons {
    display: flex;
    justify-content: center;
    list-style: none;
    padding-left: 0;
}

.social-icons>li {
    margin: 10px;
}

.social-icons a {
    position: relative;
    background-color: #36a9d8;
    display: block;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    transition: background-color .3s ease-in-out;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: .25rem;
    border: 1px solid #ffffff;
}

.social-icons a:hover {
    background-color: #bee1ef;
    border: 1px solid #ffffff;
}

.social-icons a::before {
    content: "";
    position: absolute;
    width: 1.2rem;
    height: 1.2rem;
    left: .65rem;
    top: .65rem;
    background: transparent no-repeat center center;
    background-size: 100% 100%;
}

.social-icons .social-icon-fb::before {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512'%3E%3Cpath fill='%23ffffff' d='M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z'/%3E%3C/svg%3E");
}

.social-icons .social-icon-youtube::before {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 576 512'%3E%3Cpath fill='%23fff' d='M549.655 124.083c-6.281-23.65-24.787-42.276-48.284-48.597C458.781 64 288 64 288 64S117.22 64 74.629 75.486c-23.497 6.322-42.003 24.947-48.284 48.597-11.412 42.867-11.412 132.305-11.412 132.305s0 89.438 11.412 132.305c6.281 23.65 24.787 41.5 48.284 47.821C117.22 448 288 448 288 448s170.78 0 213.371-11.486c23.497-6.321 42.003-24.171 48.284-47.821 11.412-42.867 11.412-132.305 11.412-132.305s0-89.438-11.412-132.305zm-317.51 213.508V175.185l142.739 81.205-142.739 81.201z'/%3E%3C/svg%3E");
}

.social-icons .social-icon-telegram::before {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3Cpath fill='%23ffffff' d='M446.7 98.6l-67.6 318.8c-5.1 22.5-18.4 28.1-37.3 17.5l-103-75.9-49.7 47.8c-5.5 5.5-10.1 10.1-20.7 10.1l7.4-104.9 190.9-172.5c8.3-7.4-1.8-11.5-12.9-4.1L117.8 284 16.2 252.2c-22.1-6.9-22.5-22.1 4.6-32.7L418.2 66.4c18.4-6.9 34.5 4.1 28.5 32.2z'/%3E%3C/svg%3E");
}

.social-icons .social-icon-instagram::before {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3Cpath fill='%23fff' d='M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z'%3E%3C/path%3E%3C/svg%3E");
}

.social-icons .social-icon-google::before {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 33.58643 35.04068'%3E%3Cpath transform='translate(-9.20679 -8.86966)' fill='%23fff' d='M27.16436,30.89721V25.165H42.79321V38.66348a19.78463,19.78463,0,0,1-6.6934,3.69823,25.691,25.691,0,0,1-8.6966,1.54863,20.7958,20.7958,0,0,1-9.38232-2.02247A15.22228,15.22228,0,0,1,11.58174,35.832a18.35889,18.35889,0,0,1-2.37495-9.48826,18.1559,18.1559,0,0,1,2.40384-9.56915,15.49673,15.49673,0,0,1,6.24653-5.94028,19.95226,19.95226,0,0,1,9.18778-1.96468q6.42567,0,10.29532,2.531a11.51263,11.51263,0,0,1,4.97527,7.43112l-7.173,1.27127a7.06107,7.06107,0,0,0-2.95665-3.99871,9.28742,9.28742,0,0,0-5.14092-1.38683,10.31257,10.31257,0,0,0-7.50046,2.78522q-2.869,2.78523-2.86805,8.49436,0,6.12519,3.10689,9.31491a9.55391,9.55391,0,0,0,7.20191,2.75055,13.76145,13.76145,0,0,0,4.407-.77239,14.55294,14.55294,0,0,0,4.0796-2.10722v-4.2857Z'%3E%3C/path%3E%3C/svg%3E");
}

.social-icons .social-icon-tiktok::before {
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M16.7,0c1.7,0,3.5,0,5.2,0c0.1,2,0.8,4.1,2.3,5.6c1.5,1.5,3.6,2.2,5.7,2.4v5.4c-1.9-0.1-3.9-0.5-5.6-1.3 c-0.8-0.3-1.5-0.8-2.2-1.2c0,3.9,0,7.8,0,11.7c-0.1,1.9-0.7,3.7-1.8,5.3c-1.7,2.6-4.8,4.2-7.9,4.3c-1.9,0.1-3.8-0.4-5.4-1.4 C4.3,29,2.4,26.1,2.1,23c0-0.7,0-1.3,0-2c0.2-2.5,1.5-5,3.4-6.6c2.2-1.9,5.3-2.8,8.2-2.3c0,2-0.1,3.9-0.1,5.9 c-1.3-0.4-2.9-0.3-4,0.5c-0.8,0.5-1.5,1.4-1.8,2.3c-0.3,0.7-0.2,1.4-0.2,2.1c0.3,2.2,2.4,4,4.7,3.8c1.5,0,2.9-0.9,3.7-2.1 c0.3-0.4,0.5-0.9,0.5-1.4c0.1-2.4,0.1-4.8,0.1-7.1C16.7,10.7,16.7,5.4,16.7,0L16.7,0z' fill='%23FFFFFF'/%3e%3c/svg%3e");
}