@keyframes blink {
    0% {
        box-shadow: none;
    }

    50% {
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
    }

    100% {
        box-shadow: none;
    }
}

.blink-btn {
    animation: blink 2s infinite;
}