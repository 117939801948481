footer {
    background: #f2f2f2;
    border-top: 2px solid #afbabf;
    border-bottom: 1px solid #fff;
    margin-top: 20px;
    color: #36a9d8;
}

footer li {
    position: relative;
    display: block;
    padding: .3rem 1.25rem;
    border-bottom: 1px solid #fff
}

.footer-title {
    color: #0096d7;
    font-weight: 400;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 35px 0 15px 0
}

footer a {
    color: #818181
}

footer a:hover {
    text-decoration: none
}

.contact li {
    position: relative;
    display: block;
    padding: .1rem .2rem;
    border-bottom: none
}

.contact .footer-title {
    justify-content: center;
    padding: 0px 40px
}

footer a:hover::before {
    content: "\f101";
    font-family: FontAwesome;
    position: absolute;
    left: 4px;
    color: #ccc;
    text-decoration: none
}


/*# sourceMappingURL=footer.css.map */