.menu-toggle {
    display: flex;
    align-items: center;
    cursor: pointer;
}


@media (max-width: 513px) {


    nav .container {
        padding: 0px !important;
        flex-wrap: nowrap;
    }

    nav {
        border-bottom: 1px solid #ccc;
    }
}

@media (max-width: 390px) {
    .btn {
        font-size: 4vw;
        margin-bottom: 8px;
    }

    .navbar-hidden {
        top: -200px !important;
    }

    .menu-toggle {
        margin-top: 3px;
        margin-bottom: 10px;
    }
}

.fullscreen-container {
    position: fixed;
    top: 60px;
    left: 0;
    width: 100%;
    height: calc(100vh - 60px);
    /* Замените 56px на высоту вашей навигационной панели */
    background-color: white;
    display: none;
    z-index: 1050;
    overflow: auto;
    overscroll-behavior: contain;
}

@media (max-width: 1280px) {
    .fullscreen-container {
        overflow: auto;
        height: 600px;
        /* Задайте фиксированную высоту, подходящую для вашего контента */
    }
}

#navbarfixedtop {
    transition: top 0.3s ease;
}

.navbar-hidden {
    top: -100px !important;
}



.menu-label {
    width: 64px;
}


@media (max-width: 576px) {
    #dynamicButton {
        min-width: 206px;
        margin-right: 10px;
    }

    #phone-number-text {
        font-size: 12px;
    }
}

#dynamicButton {
    min-width: 210px;
    margin-right: 10px;
}

#phone-number-text {
    font-size: 14px;
}

.fullscreen-container .btn {
    font-size: 20px !important;
    margin-top: 10px;
}



@media (max-width: 576px) {
    .fullscreen-container .btn {
        font-size: 25px !important;
        margin-top: 10px;
    }
}

.fullscreen-container .btn:hover {
    background-color: #fff;
    -webkit-box-shadow: -1px 1px 10px 0.5px rgba(0, 0, 0, 0.05);
    box-shadow: -1px 1px 10px 0.5px rgba(0, 0, 0, 0.05);
}

.fullscreen-container .btn a:hover {
    text-decoration: none !important;
    color: #007bff;
}

.fullscreen-container a:hover i {
    text-decoration: none !important;
    color: #007bff;
}

.fullscreen-container a .btn:hover {
    background-color: #fff;
    color: #007bff;

}

.fullscreen-container a .btn:hover i {
    color: #000 !important;
}

.fullscreen-container .btn.btn-outline-info:hover i {
    color: #000 !important;
}


.fullscreen-container .btn.btn-outline-info:hover {
    border-color: #0000005e !important;
}

.fullscreen-container a:hover {
    color: #000 !important;
}