   /*  ------ stert module-remont -------   */
   
   .module-remont {
       box-shadow: -5px 1px 10px rgba(215, 215, 214, 0.3) !important;
       border: 1px solid #dedada50;
       background-color: #fff;
       overflow: hidden;
       color: #007bff;
       padding-bottom: 25px !important;
       margin-bottom: 30px;
       text-align: center;
   }
   
   .module-remont:hover {
       box-shadow: none;
       border: 1px solid #dedada1f;
   }
   
   .module-remont h2 {
       padding-bottom: 10px;
       padding-top: 10px;
       text-align: center;
       max-width: 300px;
       margin: auto!important;
       background-color: #007bff80;
       color: #fff;
       font-size: 20px !important;
       font-family: helvetica;
   }
   
   .module-remont:hover h2 {
       font-size: 20px !important;
       background-color: #007bffe6;
       color: #fff;
       text-decoration: none !important;
   }
   
   .module-remont:hover a {
       text-decoration: none !important;
   }
   
   .module-remont img {
       margin-bottom: 30px;
       margin-top: 30px;
       transition: transform 0.25s;
   }
   
   .module-remont img:hover {
       -webkit-filter: grayscale(100%);
       /* Safari 6.0 - 9.0 */
       filter: grayscale(100%);
       transform: scale(1.1);
   }
   
   .module-remont button {
       width: 100%;
       max-width: 300px;
       margin: auto!important;
       background-color: #7fbdff;
       border-color: #e7e9e9;
   }
   
   .module-remont:hover button {
       text-shadow: 0 1px 0 #2c9cad;
       background-color: #2f96c2;
       border-color: #34b3c7;
   }
   
   @media (min-width:320px) {
       .module-remont h2 {
           font-size: 20px !important;
       }
   }
   
   @media (min-width:1025px) {
       .module-remont h2 {
           font-size: 20px !important;
       }
   }
   
   @media (min-width:1200px) {
       .module-remont h2 {
           font-size: 20px !important;
       }
   }
   
   @media (min-width:1300px) {
       .module-remont h2 {
           font-size: 22px !important;
       }
   }
   /*  ------ end module-remont -------   */
   /*  ------ stert mobilnyy-ofis -------   */
   
   .mobilnyy-ofis {
       align-items: center!important;
       display: flex!important;
       min-height: 300px;
       line-height: 40px !important;
   }
   
   .mob-ofice-left {}
   
   .mob-ofice-right {
       text-align: center!important;
   }
   
   .mob-ofice-right h2 {
       color: #36a9d8 !important;
   }
   
   .mob-ofice-right img {
       max-width: 100%;
       height: auto;
   }
   
   .mob-ofice-perevahy svg {
       height: 70px !important;
       width: 70px !important;
       margin-bottom: 25px;
   }
   
   .mob-ofice-perevahy h5 {
       font-size: 25px !important;
   }
   
   .mob-ofice-perevahy h2 {
       color: #0096d3 !important;
   }
   
   @media (max-width: 769px) {
       .mob-ofice-right {
           margin-top: 25px;
           margin-bottom: 25px;
       }
       .mob-ofice-left {
           margin-top: 25px;
       }
   }
   /*  ------ and mobilnyy-ofis -------   */
   /* STAR block reviews for a page reviews  */
   
   .header-reviews {
       /* box-shadow: 0 5px 21px -10px rgb(0 0 0 / 14%);*/
       padding-top: 20px;
       padding-bottom: 20px;
       position: relative;
       background-color: #f7f6f6;
   }
   
   .header-reviews .name {
       font-weight: 500;
       font-size: 17px;
       margin-bottom: 0px;
   }
   
   .user-img {
       margin-left: 50px;
   }
   
   .text-reviews p {
       text-indent: 25px;
       padding-top: 20px;
   }
   
   .text-reviews {
       padding: 25px;
       margin-top: 0px;
       margin-bottom: 50px;
       /* box-shadow: 0 5px 21px -10px rgb(0 0 0 / 14%);*/
       border: 1px solid #f7f6f6;
       background-color: #fff;
       position: relative;
   }
   /* END block reviews for a page reviews  */
   /* block carousel-our-works STAR */
   
   .carousel-our-works {
       position: relative;
       margin-top: 0px;
   }
   
   .carousel-our-works-heading {
       font-size: 40px;
       color: #36a9d8!important;
       margin-bottom: 10px !important;
       padding-bottom: 15px;
   }
   
   .carousel-our-works-heading svg {
       margin-bottom: 5px;
       width: 80px;
       height: 60px;
   }
   
   .carousel-our-works .item {
       display: block;
       position: relative;
       border: 1px solid rgba(0, 0, 0, 0.03);
       box-shadow: 0 5px 21px -10px rgb(0 0 0 / 34%);
       background-color: #fff!important;
       border-radius: unset;
       padding: 5px 0px;
   }
   
   .item h2 {
       height: 50px;
       width: 100%;
       position: absolute;
       top: 40%;
       left: 0px;
       color: #fff;
       z-index: 1;
       box-shadow: 0 5px 21px -10px rgb(0 0 0 / 34%);
       background: #00000047;
       padding-left: 10px;
       padding-top: 10px;
   }
   /* block carousel-our-works переваги END */
   /* block our-advantages  start */
   
   .our-advantages {
       margin-bottom: 40px;
   }
   
   .our-advantages p {
       margin-top: 30px;
       line-height: 1.4;
       font-size: 18px;
       font-family: 'Roboto', sans-serif;
   }
   
   .our-advantages .advantages {
       justify-content: center!important;
       text-align: center;
       margin-bottom: 35px;
       margin-top: 30px;
   }
   
   .our-advantages .h1 {
       text-align: center!important;
       margin-top: 50px;
       margin-bottom: 51px!important;
       color: #0096d3 !important;
   }
   /* block our-advantages переваги end*/