.hide {
    display: none;
}

.pod-klyuch div {
    min-width: 374px;
}

.line-block {
    width: 100%;
    float: left;
    margin-bottom: 19px;
    margin-left: 15px;
}

.line-block .lleft {
    width: 30px;
    float: left;
    height: 3px;
    background: #0096d7;
    background: -moz-linear-gradient(270deg, rgba(0, 150, 215, 0.75) 0%, rgba(171, 226, 3, 0.75) 100%);
    background: -webkit-linear-gradient(270deg, rgba(0, 150, 215, 0.75) 0%, #0096d7 100%);
    background: -o-linear-gradient(270deg, rgba(0, 150, 215, 0.75) 0%, rgba(0, 150, 215, 0.75) 100%);
    background: -ms-linear-gradient(270deg, rgba(153, 203, 1, 0.75) 0%, rgba(171, 226, 3, 0.75) 100%);
    background: linear-gradient(0deg, rgba(0, 150, 215, 0.75) 0%, rgba(0, 150, 215, 0.75) 100%);
}

.line-block .lright {
    width: 342px;
    max-width: 80%;
    float: left;
    height: 1px;
    margin-top: 2px;
    background: #e6e6e6;
}

.block-info-grey {
    margin: 0px 0px 20px 10px;
    background: #f5f5f5;
    padding: 15px 15px;
    width: 100%;
    display: flow-root;
    border-left: 5px solid #b4b4b4;
    text-indent: 40px;
    font-size: 17px;
    color: #727373;
}

.block-warning {
    margin: 0px 0px 20px 10px;
    background: #fcf8e3;
    padding: 15px 15px;
    width: 100%;
    border-left: 5px solid #fdf1b4;
    text-indent: 40px;
    font-size: 17px;
    color: #727373;
}

.block-info {
    margin: 0px 0px 20px 10px;
    background: #f5f5f5;
    padding: 15px 15px;
    width: 100%;
    display: flow-root;
    border-left: 4px solid #31708f;
    text-indent: 40px;
    font-size: 17px;
    color: #006884;
}

.block {
    margin: 0px 0px 20px 10px;
    background: #f5f5f5;
    padding: 15px 15px;
    width: 100%;
    display: inline-block;
    border-left: 4px solid #89b503;
    text-indent: 40px;
    font-size: 17px;
    color: #006884;
}

.block-info-blue {
    margin: 0px 0px 20px 10px;
    background: #f2f2f2;
    padding: 15px 15px;
    width: 100%;
    display: inline-block;
    border-left: 5px solid #0096d7;
    text-indent: 40px;
    font-size: 17px;
    color: #727373;
}

.block-rehau {
    box-shadow: 1px 1px 15px rgba(182, 215, 252, 0);
    border: 1px solid #f7f5f542;
    background-color: #fff;
}

.block-rehau:hover {
    box-shadow: -5px 1px 10px rgba(215, 215, 214, 0.3);
    border: 1px solid #dedada50;
}

.block-rehau img {
    margin-bottom: 15px;
}

.block-rehau-text {
    min-height: 130px;
}

.bg-grey-strip {
    background-image: url(/image/page/akcii/fon-strip.jpg);
}

.modal-content {
    background-color: #fffffff0;
}

.banner-rehau-euro-70 {
    background-image: url(/components/callback/banner/banner-rehau-euro-70.webp);
}

.naruzhnaja-obshivka-balkona {
    background-image: url(/components/callback/banner/naruzhnaja-obshivka-balkona.webp);
}

.banner-bg-house {
    background-image: url(/components/callback/banner/banner-bg-house.webp);
}

.banner-obshivka-balkona {
    background-image: url(/components/callback/banner/obshivka-balkona.webp);
}

.remont-vikon {
    background-image: url(/components/callback/banner/remont-vikon.webp);
}

.pidkluch {
    background-image: url(/components/callback/banner/pidkluch.jpg);
}

.pidvikonnya {
    background-image: url(/components/callback/banner/pidvikonnya.jpg);
}

.tsenyi {
    background-image: url(/components/callback/banner/tsenyi.jpg);
}

.zhaljuzi {
    background-image: url(/components/callback/banner/zhaljuzi.jpg);
}

.zamer {
    background-image: url(/components/callback/banner/zamer.jpg);
}

.zamer-akcia-jaluzi {
    background-image: url(/components/callback/banner/zamer-akcia-jaluzi.jpg);
}

.vynos-balkona {
    background-image: url(/components/callback/banner/vynos-balkona.jpg);
}

.otkosy-na-okna {
    background-image: url(/components/callback/banner/otkosy-na-okna.jpg);
}

.osteklenie-balkonov-lodzhij {
    background-image: url(/components/callback/banner/osteklenie-balkonov-lodzhij.webp);
}

.okna-v-chastnom-dome {
    background-image: url(/components/callback/banner/okna-v-chastnom-dome.webp);
}

.francuzskij-balkon {
    background-image: url(/components/callback/banner/francuzskij-balkon.webp);
}

.landing-1 {
    background-image: url(/image/landing/10-07-2023/header-vikna.jpg);
}

@media (max-width: 640px) and (min-width: 320px) {
    .banner-bg-house {
        background-image: url(/components/callback/banner/banner-bg-house-w640px.jpg);
    }

    .pidkluch {
        background-image: url(/components/callback/banner/pidkluch-w640px.jpg);
    }

    .pidvikonnya {
        background-image: url(/components/callback/banner/pidvikonnya-w640px.jpg);
    }

    .tsenyi {
        background-image: url(/components/callback/banner/tsenyi-w640px.jpg);
    }

    .zhaljuzi {
        background-image: url(/components/callback/banner/zhaljuzi-w640px.jpg);
    }

    .zamer {
        background-image: url(/components/callback/banner/zamer-w640px.jpg);
    }

    .zamer-akcia-jaluzi {
        background-image: url(/components/callback/banner/zamer-akcia-jaluzi-w640px.jpg);
    }

    .vynos-balkona {
        background-image: url(/components/callback/banner/vynos-balkona-w640px.jpg);
    }

    .otkosy-na-okna {
        background-image: url(/components/callback/banner/otkosy-na-okna-w640px.jpg);
    }

    .landing-1 {
        background-image: url(/image/landing/10-07-2023/header-vikna.jpg);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    }

}

.banner-zamer {
    height: 500px;
    background-position: center center;
    background-repeat: no-repeat;
    background-color: rgb(169 176 194 / 48%);
}

@media (max-width: 800px) and (min-width: 321px) {
    .banner-zamer {
        height: 700px;
        background-color: #f2f2f2
    }

    .banner-zamer p {
        font-size: 20px;
    }

    .text-background {
        background-color: #b6babdcc;
        box-shadow: 2px 3px 5px #00000057;
    }
}

.banner-zamer p {
    font-size: 25px;
    color: #fff;
    text-shadow: 1px 1px 1px #00000075;
    font-weight: 600;
}

.container blockquote {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 40px 70px 25px 75px;
    position: relative;
    font-size: 18px;
    font-weight: 300;
    font-style: italic;
    line-height: 24px;
    border-left: 4px solid #90c4fb;
    background: #f3f5ff;
}

.container blockquote:after {
    content: "";
    display: block;
    width: 42px;
    height: 28px;
    position: absolute;
    right: 28px;
    bottom: 10px;
    transform: rotate(180deg);
    background: url(https://balkony-vikna.kyiv.ua/image/elementdizain/svg/quotes-green.svg) no-repeat center/contain;
}

.container blockquote>div p:first-child:before {
    content: "";
    display: block;
    width: 42px;
    height: 28px;
    position: absolute;
    left: 14px;
    top: 10px;
    background: url(https://balkony-vikna.kyiv.ua/image/elementdizain/svg/quotes-green.svg) no-repeat center/contain;
}

.header-vybir {
    padding-left: 5%;
    padding-top: 20px;
    padding-bottom: 25px;
}

.blick:before {
    content: '';
    width: 100%;
    height: 200%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.5;
    filter: blur;
    background: linear-gradient(to left, transparent 0%, #fff 40%, #fff 60%, transparent 100%);
    transform: translate(-100%, -25%) rotate(10deg);
    animation: blick 5s infinite;
}

@keyframes blick {
    0% {
        transform: translate(-100%, -25%) rotate(10deg);
    }

    20% {
        transform: translate(100%, -25%) rotate(10deg);
    }

    100% {
        transform: translate(100%, -25%) rotate(10deg);
    }
}


/*# sourceMappingURL=content.css.map */